.timespan-display {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-items: center;
    align-items: center;
}

.timespan-display__input {
    padding: 1px;
    border-radius: 3px;
    display: inline-block;
    min-width: auto;
    width: 100%;
    border: 1px solid #d1d1d1;
    text-align: center;
}

.timespan-display__divider {
    padding-left: 1rem;
    padding-right: 1rem;
}
