.replay-list-page {
    width: 100%;
    padding: 1em;
}

@media (min-width: 1200px) {
    .replay-list-page {
        max-width: 1000px;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        column-gap: 1em;
    }
}

.replay-list-page__replay-list {
    grid-row: span 2;
}

.replay-list-page__new-replay,
.replay-list-page__replay-list
{
    margin-bottom: 1em;
}