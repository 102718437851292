html,
body,
#root {
  position: relative;
  margin: 0;
}

nav.app__nav {
  position: relative !important;
}

main {
  flex-grow: 1;
  min-height: 0;
  display: flex;
  justify-content: center;
}

.overflow-scroll {
  overflow: scroll;
}

.flex-grid__column--small-gutter {
  padding-left: 2px !important;
  padding-right: 2px !important;
}