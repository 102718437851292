@media (max-width: 1200px) {
    .bbo-panel__last-sale,
    .bbo-panel__best-bids,
    .bbo_panel__best-offers {
        margin-bottom: 20px;
    }
}

@media (min-width: 1200px) {
    .bbo-panel {
        display: flex;
        flex-direction: row;
    }
}

