.replay-list {
    list-style: none;
    padding-inline-start: 0;
}

.replay-list-item {
    user-select: none;
}

.replay-icon {
    border-radius: 50%;
    border: 1px solid;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    background-color: transparent;
    border-color: gray;
    color: gray;
}

.replay-icon-tooltip:not(:last-of-type) {
    margin-bottom: 6px;
}

.replay-icon--quotes.replay-icon--active {
    background-color: green;
    color: white;
}

.replay-icon--trades.replay-icon--active {
    background-color: orange;
    color: white;
}