.replay-page {
    width: 100%;
    padding: 1em;
}

@media (min-width: 1200px) {
    .replay-page {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(350px, 1fr);
        column-gap: 1em;
    }
}

.replay-page__section {
    margin-bottom: 1em;
}