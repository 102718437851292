.autocomplete__wrapper {
    margin-bottom: 1rem;
    position: relative;
}

.autocomplete__wrapper .form__item,
.downshift-1-input-wrapper {
    margin-bottom: 0 !important;
}

.autocomplete__menu,
.autocomplete__menu--open {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 100;
    display: none;
    border: 1px solid lightgray;
    box-shadow: 0 6px 5px 1px lightgray;
}

.autocomplete__menu--open {
    display: block;
}

.autocomplete__menu .autocomplete__option {
    font-size: 0.8rem;
    padding: 0.5rem 0.2rem !important;
    line-height: 1rem;
    cursor: pointer;
}

.autocomplete__option a {
    font-size: 0.9rem !important;
}

.autocomplete__option.autocomplete__option--highlighted {
    background-color: #f0f0f0;
}

.autocomplete__option:hover a,
.autocomplete__option--highlighted a {
    text-decoration: underline;
}