.time-slider {
    padding: 5px 40px;
    user-select: none;
}

.time-slider__inner {
    position: relative;
    width: 100%;
    height: 30px;
}

    .time-slider .time-slider__track {
        position: absolute;
        width: 100%;
        top: 10px;
        height: 10px;
        border-radius: 10px;
        overflow: hidden;
        background-color: rgb(240, 240, 240);
        border: 1px solid rgb(230, 230, 230);
        cursor: pointer;
    }

    .time-slider .time-slider__handle {
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);;
        background:white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: rgb(0 0 0 / 30%) 0px 0px 2px;
        user-select: none;
        border-radius: 15px;
    }

    .time-slider__handle__leftarrow, .time-slider__handle__rightarrow {
        padding: 5px 10px;
    } 

    .time-slider__handle__label { 
        padding: 5px 10px;
    }
