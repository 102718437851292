.playback-controls__container {
    padding: 4px 20px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.playback-controls {
    display: block;
}

.playback-controls__slider-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 200px;
    opacity: 1;
    justify-self: right;
}

.playback-controls__slider-container--hidden {
    opacity: 0;
}

.playback-controls__slider-number {
    display: inline-block;
    padding: 0 14px;
}
