.recharts-responsive-container {
    user-select: none;
}

.chart-outer-wrapper {
    position: relative;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
}

.chart-wrapper {
    position: relative;
    max-height: 100%;
    height: 100%;
    overflow: hidden;
}

.chart-interactivity-zone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.charts-container {
    display: grid;
    grid-template-rows: 80% 20%;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.top-chart, .bottom-chart {
    position: relative;
}

.chart-buttons {
    z-index: 110;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
}

.chart-buttons > div {
    margin-bottom: 7px;   
}

.recharts-scatter .recharts-scatter-symbol .recharts-dot:hover {
    r: 3;
 }

 .scatter-tooltip {
     display: block;
     position: absolute;
     visibility: visible;
     top: 0;
 }

.trade-tooltip,
.offer-tooltip,
.bid-tooltip,
.summary-tooltip {
    padding: 0.25rem 1rem;
    font-size: 80%;
    border: 1px solid;
    z-index: 100;
}

.trade-tooltip,
.summary-tooltip {
    background-color: #db700c;
    border-color: orange;
}

.bid-tooltip {
    background-color: #3569b8;
    border-color: blue;
}

.offer-tooltip {
    background-color: rgb(45, 167, 45);
    border-color: green;
}

.summary-tooltip th {
    color: white;
}

.summary-tooltip tr {
    text-align: right;
}